import helpersUtmKeys from '~/helpers/utmTracking/utmKeys'

import type { CookieRef } from '#app'
import type { AvailableUtmKeys } from '~/helpers/utmTracking/utmKeys'
import type DiscoveryAvailableOption from '~/types/discoveryOption'

/**
 * Contrary to what most in the tech team where led to believe this piece of tracking is **not** part of the legacy tracking.
 * It sure looks like it but trust me, **{@link https://heygroover.slack.com/archives/C06MWF0ADCM/p1716819187325789 | it's not}**.
 * These tracking values work in conjunction with `middleware/utm.global.ts`. the middleware captures the url querries if they are valid and this sends them if they where stored.
 * These values are used for ads down the line ? Don't quote me on that one tho.
 */
export function useUtmTracking() {
  const cookies = helpersUtmKeys.reduce(
    (accumulator, cookieKey) => {
      accumulator[cookieKey] = useCookie(cookieKey)
      return accumulator
    },
    {} as Record<AvailableUtmKeys, CookieRef<string | null | undefined>>,
  )
  const referrerCookie = useCookie('referer')

  function getUtmMapping() {
    return (
      Object.entries(cookies) as [
        AvailableUtmKeys,
        CookieRef<string | null | undefined>,
      ][]
    ).reduce(
      (accumulator, [key, { value }]) => {
        if (!value) return accumulator
        accumulator[key] = value
        return accumulator
      },
      {} as Record<AvailableUtmKeys, string>,
    )
  }

  function utmUserTracking(): Promise<never> {
    return $coreFetch.$post('/tracking/event/', {
      event_type: 'signup',
      referer: referrerCookie.value,
      ...getUtmMapping(),
    })
  }

  function utmPayinTracking(paymentType: 'paypal' | 'stripe'): Promise<never> {
    return $coreFetch.$post('/tracking/event/', {
      event_type: 'payment',
      payment_type: paymentType,
      referer: referrerCookie.value,
      ...getUtmMapping(),
    })
  }

  function utmDiscoveryTracking(
    choice: DiscoveryAvailableOption | '',
    optionalInput: string,
  ): Promise<never> {
    return $coreFetch.$post('/tracking/event/', {
      event_type: 'discovery',
      discovery_source: choice.length > 0 ? choice : 'skipped_question',
      details: optionalInput,
      referer: referrerCookie.value,
      ...getUtmMapping(),
    })
  }

  return { utmDiscoveryTracking, utmPayinTracking, utmUserTracking } as const
}
